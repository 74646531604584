<template>
  <deal-layout>
    <section slot="header">
      <div class="columns">
        <div class="column is-narrow">
          <div class="merchant-header-logo box" @click="openMerchantHomepage">
            <img v-lazy="merchant.logo" v-if="merchant.logo" :alt="merchant.name">
            <i class="fas fa-store fa-3x has-text-grey" v-else></i>
          </div>
        </div>

        <div class="column">
          <div class="merchant-header-info content">
            <i18n path="merchants.vouchers" tag="h1" class="title is-4 has-text-weight-black">
              <template #merchant>
                {{ merchantName }}
              </template>
            </i18n>

            <p>
              <span class="tag is-default" v-show="merchant.codesCount > 0" @click="toggleOnlyDealType('code')">
                <span class="has-text-weight-semibold">{{ merchant.codesCount }}</span>
                &nbsp;{{ $tc( 'merchants.live-codes', merchant.codesCount ) }}
              </span>
              &nbsp;
              <span class="tag is-default" v-show="merchant.offersCount > 0" @click="toggleOnlyDealType('deal')">
                <span class="has-text-weight-semibold">{{ merchant.offersCount }}</span>
                &nbsp;{{ $tc( 'merchants.live-offers', merchant.offersCount ) }}
              </span>
            </p>

            <p v-show="merchant.offersCount > 0">
              <span class="has-text-grey" v-t="`merchants.only-codes`"></span>
              &nbsp;&nbsp;
              <p-checkbox
                :direct="onlyDealType === 'code'"
                @change="toggleOnlyDealType('code')"
              ></p-checkbox>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section slot="main">
      <deal-list
        :filters="{ pattern, onlyDealType }"
        :deals="deals"
        :fallback="featuredDeals"
        :superior="merchantName"
      ></deal-list>
    </section>

    <section slot="aside" class="merchant-side-bar" style="paddingTop: 15px">

      <div v-show="!userSubscribed">
        <banner class="content">
          <div class="deal-merchant">
            <div class="image is-128x128" style="margin: auto; padding: 10%">
              <img v-lazy="`$/images/logo_small.png`" alt="Pouch">
            </div>
          </div>

          <h3 class="title is-5" v-t="`deals.ads.newsletter.main`"></h3>

          <p-form :handler="emailRegistration" :validator="emailValidator">
            <template slot="fields" slot-scope="{ errors, data }">

              <p-field :errors="errors.Checkbox">
                <p-tickbox @change="data.ReceiveNewsletter = receiveNewsletterTextActive = $event"></p-tickbox>
                &nbsp;
                <span v-t="`home.newsletter`"></span>
              </p-field>

              <p-field
                :errors="errors.Email"
                class="column is-centered"
              >
                <p-input
                  type="email"
                  :placeholder="$t('deals.ads.newsletter.placeholder')"
                  @keyup="toggleConsentInfo"
                  v-model="data.Email"
                ></p-input>
              </p-field>

              <div v-if="consentInfoActive">
                <span v-t="`home.consent`"></span>
                &nbsp;
                <span>
                  <a href="/privacy-policy"  v-t="`links.privacy-policy`"></a>.
                </span>
              </div>

            </template>

            <template slot="actions" slot-scope="{ stage, stages }">
              <p-field class="column">
                <p-button white fullwidth class="has-text-white has-background-primary"
                  :loading="stage === stages.SUBMITTED"
                  v-t="`deals.ads.newsletter.cta`"
                ></p-button>
              </p-field>
            </template>
          </p-form>
        </banner>
      </div>
    </section>
  </deal-layout>
</template>

<script>
import Banner from './components/Banner'
import { mapGetters, mapState } from 'vuex'
import DealList from './components/DealList'
import DomainData from '@/core/services/Domain'
import { mixpanel } from '@/core/services/Analytics'
import EmailValidator from '@/core/validators/EmailValidator'

export default {
  components: { Banner, DealList },

  data () {
    return {
      pattern: '',
      onlyDealType: null,
      emailValidator: new EmailValidator(),
      receiveNewsletterTextActive: false,
      consentInfoActive: false,
      userSubscribed: false
    }
  },

  computed: {
    ...mapState({
      featuredDeals: state => state.featuredVouchers
    }),

    ...mapGetters({
      deals: 'deals/currentMerchantDeals',
      merchant: 'deals/currentMerchant'
    }),

    filteredDeals () {
      return this.deals
        .filter(d =>
          d.label.match(new RegExp(this.pattern, 'i')) ||
          (d.coupon_code && d.coupon_code.match(new RegExp(this.pattern, 'i')))
        )
        .filter(d => d.coupon_code || !this.onlyCodes)
    },

    merchantName () {
      return this.merchant.name || this.$route.params.merchant
    },

    merchantSocial () {
      return (this.merchant.social || []).filter(s => s.link)
    }
  },

  methods: {
    toggleOnlyDealType (type) {
      this.onlyDealType = this.onlyDealType === type ? null : type
    },

    toggleConsentInfo (event) {
      this.consentInfoActive = !!event.target.value
    },

    dispatchSocialClickedEvent (social) {
      this.$dispatcher.fire('app.retailerSocialClicked', {
        route: this.$route,
        social: social.name,
        url: social.link
      })
    },

    dispatchMessengerAdClickedEvent () {
      this.$dispatcher.fire('app.messengerAdClicked', {
        route: this.$route
      })
    },

    openMerchantHomepage () {
      const domain = new DomainData(this.$route.params.merchant)
      const url = domain.deriveOrigin()

      if (url) {
        window.open(url, '_blank').focus()
      }
    },

    sendFormSubmitEvent (data) {
      const id = localStorage.getItem('uid')
      const parameters = {
        newsletter: data.ReceiveNewsletter,
        userAction: 'formSubmit',
        distinct_id: id
      }
      mixpanel.identify(id)
      mixpanel.event('Merchant Page Signup', parameters)
    },

    async emailRegistration (data) {
      if (!data.Email || !data.Email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
        return Promise.reject({ code: 'InvalidParameterException' })
      }

      if (!data.ReceiveNewsletter) {
        return Promise.reject({ code: 'InvalidParameterException' })
      }

      const mailchimpTemplateNewsletter = this.$mailchimp.getTemplate(data.Email, 'deals_newsletter')

      this.$store.dispatch('postDataToMailchimp', mailchimpTemplateNewsletter).catch(console.err)
      this.$messages.success(this.$t('home.congratulations-newsletter'), '', true)

      this.sendFormSubmitEvent(data)
      this.userSubscribed = true
    }
  }
}
</script>

<style lang="sass" scoped>
.input
  text-align: right
  border-color: transparent
  box-shadow: none

  &:not(:focus)
    background: none
</style>
