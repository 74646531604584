
export default {
  /**
   * Sanitizes the provided string value.
   *
   * @param {string} value
   * @return {string}
   */
  title (value) {
    return value.toLowerCase()
      .replace(/\w\S*/g, w => this._isAcronym(w)
        ? w.toUpperCase()
        : w.charAt(0).toUpperCase() + w.substr(1)
      )
  },

  /**
   * Cuts the string after specified delimiters.
   *
   * @param {string} value
   * @return {string}
   */
  cut (value) {
    return [
      w => w.split(',')[1] ? w.split(',')[0] : false,
      w => w.split('(')[1] ? w.split('(')[0] : false,
      w => w.split(' with ')[0].length > 20 && w.split(' with ')[1] ? w.split(' with ')[0] : false,
      w => (w.match(/^([^\s]+\s*){1,12}/) || [])[0]
    ].reduce((carry, cutter) => {
      return cutter(carry) || carry
    }, value)
  },

  /**
   * Parses title from the provided url.
   *
   * @param {string} url
   * @return {string}
   */
  fromUrl (url) {
    return url.charAt(0).toUpperCase() + url.split('.')[0].substr(1)
  },

  /**
   * Return SEO optimized date.
   *
   * @return {string}
   */
  seoDate () {
    return new Date().toLocaleDateString('en-GB', { month: 'long', year: 'numeric' })
  },

  /**
   * Parses string from a provided slug.
   *
   * @param {string} slug
   * @return {string}
   */
  fromSlug (slug) {
    return this.title(slug.replace(/-/g, ' '))
  },

  /**
   * Whether a word should be all in capital.
   *
   * @private
   * @param {string} word
   * @return {boolean}
   */
  _isAcronym (word) {
    return !![
      'diy', 'vip', 'bbq'
    ].find(w => w === word)
  }
}
