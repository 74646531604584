<template>
  <!-- For website, we show deal only in the case it is NOT a unique code -->
  <div
    :id="deal.id"
    :class="['deal', !connectedNext || 'is-connected-to-next', !connectedPrevious || 'is-connected-to-previous']"
    v-show="!deal.has_codes"
  >
    <div class="deal-merchant">
      <router-link class="deal-logo" v-if="deal.merchant && showLogo" :to="{ name: 'merchant', params: { merchant: deal.merchant.url } }">
        <img v-lazy="deal.merchant.logo" v-if="deal.merchant.logo" :alt="deal.merchant.name">
        <i class="fas fa-store fa-3x has-text-grey" v-else></i>
      </router-link>
      <span
        class="has-text-primary has-text-weight-semibold"
        v-t="`deals.deal-type.${deal.voucher_type === 'code' ? 'code' : 'deal'}`"
      ></span>
    </div>

    <div class="deal-description">
      <div class="has-text-grey has-text-weight-semibold">
        {{ deal.merchant ? deal.merchant.name : '' }}
      </div>
      <div class="is-size-5 has-text-weight-semibold">{{ label }}</div>
      <a
        class="is-size-7"
        v-if="shouldLabelExpand"
        @click="isLabelExpanded = !isLabelExpanded"
        v-t="`deals.labels.${isLabelExpanded ? 'less' : 'more'}`"
      ></a>
      <div class="has-text-grey-light is-size-7">
        <i class="fas fa-calendar-alt"></i>&nbsp;
        <i18n path="deals.labels.expiry" tag="span">
          <template #date>
            {{ new Date(deal.end_date).toDateString() }}
          </template>
        </i18n>
        &nbsp;
        <a class="has-text-grey-light" @click="tandcsModalActive = true">
          <i class="fas fa-info-circle"></i>
          &nbsp;<span v-t="`deals.labels.terms`"></span>
        </a>
      </div>
    </div>

    <div class="deal-action" v-if="deal.voucher_type === 'code'" @click="openDeal">
      <span class="deal-button-code">{{ deal.coupon_code }}</span>
      <button class="button deal-button has-code is-tertiary">
        <span v-if="copied">
          <i class="fas fa-check"></i>
          <span v-t="`deals.labels.copied`"></span>
        </span>
        <span v-else v-t="`deals.labels.copy-shop`"></span>
      </button>
    </div>

    <div class="deal-action" v-else @click="openDeal">
      <button class="button deal-button is-secondary">
        <span v-t="`deals.labels.shop`"></span>
      </button>
    </div>

    <p-modal :active="tandcsModalActive" @close="tandcsModalActive = false">
      <div class="has-padding-4 content">
        <p
          class="title is-3 has-text-centered has-text-weight-black"
          v-t="`links.terms-and-conditions`"
        ></p>

        <div class="columns">
          <div class="column is-4 is-hidden-mobile" style="display: flex; align-items: center">
            <img v-lazy="deal.merchant.logo" v-if="deal.merchant.logo" :alt="deal.merchant.name">
            <i class="fas fa-store fa-3x has-text-grey" v-else></i>
          </div>
          <div class="column is-8">
            <div class="has-text-grey has-text-weight-semibold">
              {{ deal.merchant ? deal.merchant.name : '' }}
            </div>
            <div class="is-size-5 has-text-weight-semibold">{{ label }}</div>
          </div>
        </div>

        <ul class="has-text-grey">
          <i18n path="deals.labels.expiry" tag="li">
            <template #date>
              {{ new Date(deal.end_date).toDateString() }}
            </template>
          </i18n>
          <li v-t="`deals.terms.availability`"></li>
          <li v-t="`deals.terms.rights-reserved`"></li>
          <li v-if="deal.terms_and_conditions">{{ deal.terms_and_conditions }}</li>
          <li v-else v-t="`deals.terms.see-retailer`"></li>
        </ul>

        <div class="deal-action" v-if="deal.voucher_type === 'code'" @click="openDeal">
          <span class="deal-button-code">{{ deal.coupon_code }}</span>
          <button class="button deal-button has-code is-tertiary">
            <span v-if="copied">
              <i class="fas fa-check"></i>
              <span v-t="`deals.labels.copied`"></span>
            </span>
            <span v-else v-t="`deals.labels.copy-shop`"></span>
          </button>
        </div>

        <div class="deal-action" v-else @click="openDeal">
          <button class="button deal-button is-secondary">
            <span v-t="`deals.labels.shop`"></span>
          </button>
        </div>
      </div>
    </p-modal>
  </div>
</template>

<script>
// TODO: This component needs to be revamped.
import Str from '@/core/services/Str'
import Clickout from '@/core/services/Clickout'
import DomainData from '@/core/services/Domain'

export default {
  props: {
    deal: Object,
    index: Number,
    showLogo: {
      type: Boolean,
      default: true
    },
    connectedNext: Boolean,
    connectedPrevious: Boolean
  },

  data () {
    return {
      copied: false,
      isLabelExpanded: false,
      tandcsModalActive: false
    }
  },

  computed: {
    // TODO: handle many languages!!!!
    label () {
      const label = this.deal.label
      // Welp... try to deal with double escaped labels...
      const l1 = new DOMParser().parseFromString(this.isLabelExpanded ? label : Str.cut(label), 'text/html')
      const l2 = new DOMParser().parseFromString(l1.documentElement.textContent, 'text/html')

      return Str.title(l2.documentElement.textContent)
    },

    shouldLabelExpand () {
      return this.deal.label.length > Str.cut(this.deal.label).length
    }
  },

  methods: {
    openDeal () {
      if (this.deal.voucher_type === 'code') {
        copyToClipboard(this.deal.coupon_code)
      }

      window.open(this.getProxiedTrackingLink(), '_blank').focus()

      // Mark the deal as copied, close any modals.
      this.copied = true
      this.tandcsModalActive = false

      // Fire a global event that a deal was clicked.
      this.$dispatcher.fire('app.dealOpened', { deal: this.deal })
      this.$emit('opened', this.deal)
    },

    getProxiedTrackingLink () {
      const domain = new DomainData(this.deal.merchant.url)
      const retailerHome = domain.deriveOrigin()

      const context = this.tandcsModalActive ? { widget: 'terms-and-conditions' } : {}
      const trackingLink = encodeURIComponent(Clickout.build(this.deal, context))

      return `/out?url=${trackingLink || retailerHome}`
    }
  }
}
</script>
