<template>
  <p-modal :active="active" @close="$emit('close')" v-hidden-with-extension v-only-extension-fit>
    <div class="has-padding-4 has-text-centered content">
      <p>
        <img v-lazy="`$/images/logo_small.png`" alt="Pouch" width="40">
      </p>

      <p class="title is-3 has-text-weight-black">
        <span class="has-text-secondary" v-t="`deal-opened-modal.greeting`"></span>
        <span v-t="`deal-opened-modal.main`"></span>
      </p>

      <p class="has-text-grey is-size-5" v-t="`deal-opened-modal.info`"></p>

      <p>
        <add-to-browser
          medium @click="$emit('close')"
          position="DealOpenedModal"
        >
          {{ $t('deal-opened-modal.cta') }}
        </add-to-browser>
      </p>
    </div>
  </p-modal>
</template>

<script>
import AddToBrowser from '@/views/partials/AddToBrowser'

export default {
  props: {
    active: Boolean
  },

  components: { AddToBrowser }
}
</script>
