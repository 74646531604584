<template>
  <div class="box has-padding-3 has-text-centered">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>
