import Uid from './Uid'
import config from '@/config'
import router from '@/router'
import Locator from './Locator'

export default {

  /**
   * Generates a clickout URL.
   *
   * @param {object} voucher The voucher clicked
   * @param {object} context Extra context including voucher sequence
   */
  build (voucher, context = {}) {
    const country = Locator.country
    const { origin, pathname } = new URL(document.location)

    const baseUrl = config.clickout.baseUrl[country]
    const clickout = new URL(`${baseUrl}${voucher.id}`)
    const params = clickout.searchParams

    params.set('domain', config.cid[country])
    params.set('co_url', `${origin}${pathname}`)
    params.set('co_dtype', 'wl')
    params.set('co_dcountry', country)
    params.set('co_vposition', String(context.pos || 0))
    params.set('co_vtype', voucher.voucher_type.toString())
    params.set('co_filter_applied', 'not_set')
    params.set('co_page_type', router.history.current.name)
    params.set('co_key_version', voucher.key_version)
    params.set('co_redu', voucher.tracking_link)
    params.set('co_retid', String(voucher.id_merchant))
    params.set('co_chash', Uid.getOrNew())
    params.set('co_ds', 'web')
    params.set('co_tsp', String(Date.now()))

    if (context.widget) {
      params.set('co_widget', context.widget)
    }

    return clickout.toString()
  }
}
