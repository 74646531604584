<template>
  <div>
    <deal
      v-for="deal in filteredDeals.slice(0, pagination.deals)"
      :key="deal.id"
      :deal="deal"
      :show-logo="showLogo"
      @opened="openDealOpenedModal"
    ></deal>

    <div class="has-text-centered has-margin-4" v-if="filteredDeals.length">
      <p-button
        text
        bold
        class="has-text-primary"
        v-show="pagination.deals < filteredDeals.length"
        @click="pagination.deals += perpage"
      >
        {{ $t('deals.show-more') }}&nbsp;
        <i class="fas fa-angle-down"></i>
      </p-button>
    </div>

    <broken-heart
      v-if="deals.length > 0 && filteredDeals.length === 0 && filters.pattern"
    >
      <i18n path="deals.apologies.cant-find-pattern" tag="span">
        <template #pattern>{{ filters.pattern }}</template>
      </i18n>
    </broken-heart>

    <broken-heart
      v-if="deals.length > 0 && filteredDeals.length === 0 && !filters.pattern"
    >
      <i18n path="deals.apologies.cant-find-codes" tag="span"></i18n>
    </broken-heart>

    <broken-heart v-if="deals.length === 0">
      <i18n path="deals.apologies.cant-find-deals" tag="span">
        <template #merchant>{{ superior }}</template>
      </i18n>
    </broken-heart>

    <section class="section" v-if="deals.length === 0">
      <h2 class="title is-5 has-text-grey" v-t="`deals.alternatives`"></h2>
      <hr>
      <deal
        v-for="(deal, key) in fallback.slice(0, 15)"
        :key="key"
        :deal="deal"
      ></deal>
    </section>

    <deal-opened-modal :active="dealOpenedModalActive" @close="closeDealOpenedModal"></deal-opened-modal>
  </div>
</template>

<script>
import Deal from './Deal'
import BrokenHeart from './BrokenHeart'
import Once from '@/core/services/Once'
import DealOpenedModal from './DealOpenedModal'

export default {
  components: { BrokenHeart, Deal, DealOpenedModal },

  props: {
    filters: Object,
    deals: Array,
    fallback: Array,
    superior: String,
    showLogo: Boolean
  },

  data: () => ({
    pagination: {
      deals: 8
    },
    perpage: 15,
    dealOpenedModalActive: false
  }),

  computed: {
    filteredDeals () {
      return this.deals
        .filter(d =>
          d.label.match(new RegExp(this.filters.pattern, 'i')) ||
          (d.coupon_code && d.coupon_code.match(new RegExp(this.filters.pattern, 'i')))
        )
        .filter(d => !this.filters.onlyDealType || d.voucher_type === this.filters.onlyDealType)
    }
  },

  methods: {
    openDealOpenedModal () {
      Once.in(2 * 60, () => this.dealOpenedModalActive = true)
    },

    closeDealOpenedModal () {
      this.dealOpenedModalActive = false
    }
  }
}
</script>
